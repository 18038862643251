<template>
    <v-container>
        <v-card outlined width="1000px" class="mx-auto pa-2 pb-0" elevation="3" style="height:auto;">
            <v-layout wrap>
                <v-select dense outlined class="ma-1" style="height:40px; width:200px;"
                id = "farm"
                ref = "farm"
                label = "농장"
                v-model = "farm"
                :items="farmItems"
                :menu-props="{top:false, offsetY:true}"
                no-data-text="자료(권한)이 없습니다."
                item-text="name"
                return-object
                @change="changeFarm()"
                @focus="comboFarms()"
                clearable
                />
                
               <v-select dense outlined class="ma-1" style="height:40px; width:200px;"
                id = "building"
                ref = "building"
                v-model = "building"
                :label="building.code ? '축사' : '축사 (선택없으면 농장전체)'" 
                :items="buildingItems"
                :menu-props="{top:false, offsetY:true}"
                no-data-text="자료(권한)이 없습니다."
                item-text="name"
                return-object
                @change="changeBuilding()"
                @focus="comboBuildings()"
                clearable
                />
                
            </v-layout>
        
            <v-layout wrap class="ma-1" >
                <v-card outlined style="height:100%;" class="rounded-pill">
                    <v-radio-group dense outlined row style="margin-top: 5px; margin-bottom: -10px;"
                    id="event" 
                    ref="event" 
                    v-model="event"
                    >
                        <v-radio style="height:20px;"
                            label="전체"
                            value="EV"
                            color="black"
                            class="mr-5 ml-2"
                            @click="listdailyTransfer()"
                        />

                        <v-radio style="height:20px;"
                            label="입식"
                            value="EVEN"
                            color="blue"
                            class="mr-5 ml-2"
                            @click="listdailyTransfer()"
                        />

                        <v-radio style="height:20px;"
                            label="이동"
                            value="EVTR"
                            color="cyan"
                            class="mr-5 ml-2"
                            @click="listdailyTransfer()"
                        />

                        <v-radio style="height:20px;"
                            label="출하"
                            value="EVSD"
                            color="green"
                            class="mr-5 ml-2"
                            @click="listdailyTransfer()"
                        />

                        <v-radio style="height:20px;"
                            label="도폐"
                            value="EVDT"
                            color="red"
                            class="mr-5 ml-2"
                            @click="listdailyTransfer()"
                        />
                    </v-radio-group>
                </v-card>


                <v-row class="justify-end">
                    <v-btn class="elevation-5 ma-3 rounded-pill normal-btn"
                        style="height:40px; width:110px;"
                        id="dailyTransfer"
                        :loading="loading"
                        ref="dailyTrasfer"
                        @click="listdailyTransfer()">
                        <img src="@/assets/icon_refresh.svg" alt="새로고침" />
                    조회
                    </v-btn>
                </v-row>
            </v-layout>
        </v-card>
        
        <v-card outlined color="transparent" width="1000px" class="mx-auto mt-2">
                <v-card outlined class="calendar rounded-pill" width="auto">
                <v-icon
                    color=#3a4f3f
                    class="ml-2"
                >
                    mdi-calendar
                </v-icon>
                 오늘날짜 : {{this.today}}
                </v-card>
        </v-card>

        <v-card width="1000px" height="auto" rounded-lg class="ma-2 mx-auto">
            <v-data-table dense height="600px" 
                fixed-header
                hide-default-footer
                :items-per-page="500"
                :loading="loading"
                id="items"
                ref="items"
                :headers="headers"
                :items="items"
                :sort-desc.sync="istrue"
                no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
                class="elevation-3 "
                single
            >

           <template v-slot:item="{ item }">
                    <tr>
                        <td>{{item.building}}</td>
                        <td>{{item.room}}</td>

                        <td >
                            <v-chip
                                :color="getColor(items.pigcnt)"
                                style="width:45px; "
                                class="justify-center pa-1"
                                dark
                                small >
                            {{item.pigcnt}}
                            </v-chip>   
                        </td>

                        <td :style="[item.today > -1 ? {color:'blue'} : {color:'red'}, item.today === '0' ? {color:'black'}: {}]">
                            <div class="d-flex justify-center">
                                {{item.today}}
                            </div>
                        </td>

                        <td :style="[item.secondDate > -1 ? {color:'blue'} : {color:'red'}, item.secondDate === '0' ? {color:'black'} :{}]">
                            <div class="d-flex justify-center">
                                {{item.secondDate}}
                            </div>
                        </td>

                        <td :style="[item.thirdDate > -1 ? {color:'blue'} : {color:'red'}, item.thirdDate === '0' ? {color:'black'} :{}]">
                            <div class="d-flex justify-center">
                            {{item.thirdDate}}
                            </div>
                        </td>

                        <td :style="[item.fourthDate > -1 ? {color:'blue'} : {color:'red'}, item.fourthDate === '0' ? {color:'black'} :{}]">
                            <div class="d-flex justify-center">
                            {{item.fourthDate}}
                            </div>
                        </td>

                        <td :style="[item.fifthDate > -1 ? {color:'blue'} : {color:'red'}, item.fifthDate === '0' ? {color:'black'} :{}]">
                            <div class="d-flex justify-center">
                            {{item.fifthDate}}
                            </div>    
                        </td>

                        <td :style="[item.sixthDate > -1 ? {color:'blue'} : {color:'red'}, item.sixthDate === '0' ? {color:'black'} :{}]">
                            <div class="d-flex justify-center">
                            {{item.sixthDate}}
                            </div>
                        </td>

                        <td :style="[item.fromdate > -1 ? {color:'blue'} : {color:'red'}, item.fromdate === '0' ? {color:'black'} :{}]">
                            <div class="d-flex justify-center">
                            {{item.fromdate}}
                            </div>
                        </td>
                    </tr>

            </template> 
            
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import VueCookies from "vue-cookies";

var vDate = new Array();

export default {
    name: "DailyTransfer",

    created() {
        Apis.pageCount({
          page_name: this.$route.name,
          url: this.$route.path,
          division: "pigro",
        }, () => {
            // console.log("pageCount 호출",res);
        }).catch((err) => {
            console.log("pageCount 호출 오류", err);
        });
        this.comboFarms();
        this.comboBuildings();
        this.makeHeaders();
        let cdate = new Date();
        this.today = dateUtil.format(cdate, "M월 D일");

        let cookie_info = VueCookies.get("dailyTransfer_info"); // 이전 조회정보를 참조
        if (cookie_info) {
          // console.log("쿠키",cookie_info);
          this.farm = cookie_info.farm || {};
          this.building = cookie_info.building || {};
          this.event = cookie_info.event;
          this.listdailyTransfer();
        } else {  // 이전 조회정보가 없을 경우 첫번째 농장 디폴트로 참조
          setTimeout( () => {
                    this.farm = this.farmItems[0];
                    this.listdailyTransfer();
                  }, 1000);

        }
    },
    data() {
        return {
            
            // 날짜부분에는 현재 날짜의 +1..+7 더한 날짜가 출력되도록
            headers:[
                {text: '축사',  value:'building', sortable:false ,width: "100px",class: 'green darken-4 white--text' },
                {text: '돈방' , value:'room', width: "100px", class: 'green darken-4 white--text' },
                {text: '현재', align: 'center', value:'pig_cnt', width: "75px", sortable:false, class: 'green darken-4 white--text' },
            ],
            items:[],

            farm: {},
            farmItems: [{ code: "", name: "" }],
            building: {},
            buildingItems: [{ code: "", name: "" }],

            event: "EV",
            loading: false,

            today:"",
            frdate:"",
            istrue:true,

        };
    },
    methods: {
				makeHeaders() {
            // 헤더에 날자를 세팅
            let cdate = new Date();
            for (var i = 0; i < 7; i ++) {
                let d1 = dateUtil.addDays(cdate, i * -1);
                let td = dateUtil.format(d1,"M/D");      //헤더에 쓰일 날자
                let vd = dateUtil.format(d1,"yyyy-MM-dd"); //API 데이타에서 추출을 위한 날자
                vDate.push(vd);
								this.headers.push(
                  {text: td , value: vd, width:"70px", align: 'center', sortable:false, class: 'green darken-4 white--text' },
                );
						}
         // 입식, 이동 (증가):빨간색 / 출하, 도폐, 이동 (감소):파란색
				},
        listdailyTransfer() {
            if (!this.farm.code) return;
            this.items = [];
            this.$store.commit("resMessage","");
            // this.loading = true;
            Apis.roomDailyCountByEvent({
                farm: this.farm.code,
                building: this.building && this.building.code || "",
                event: this.event == "EV" ? "" : this.event,
                stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"),
                // stand_date: "2022-05-20",
                day: Number(7),
            }, (res) => {
                if (res.result) {
                    for (let i in res.data) {
                        this.items.push({
                            building: res.data[i].building_name,
                            room: res.data[i].room_name,
                            pigcnt: res.data[i].pig_cnt,
                            today: res.data[i][vDate[0]],
                            secondDate: res.data[i][vDate[1]],
                            thirdDate: res.data[i][vDate[2]],
                            fourthDate: res.data[i][vDate[3]],
                            fifthDate: res.data[i][vDate[4]],
                            sixthDate: res.data[i][vDate[5]],
                            seventhDate: res.data[i][vDate[6]],
                        });
                    }
                    this.$store.commit("resMessage",res.message);
                    let cookie_info = { 
                                  farm: this.farm,
                                  building: this.building,
                                  event: this.event,
                                }
                    VueCookies.set("dailyTransfer_info" ,cookie_info, 60 * 60 * 9); // 이전조회정보 9시간 저장 : 하루동안 조회정보 재입력 불필요하게 유지
                }
                else {
                    alert(res.message);
                }
            }).catch((err) => {
                console.log("roomDailyCountByEvent API 호출 오류", err);
                alert(err);
            });
        }, 
        // 7일동안 돈방 내 이벤트가 생겼다면, 오렌지 모양으로 현재두수를 보여주고, 만약에 이벤트가 일어나지 않았다면 회색으로 보이도록 
        getColor(event){
            if (event!==0) return 'orange'
            else return 'gray'
        },

        comboFarms() {
            Apis.comboFarms({
                company_cd: "",
                stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"),
            }, (res) => {
                if (res.result) {
                    this.farmItems = [];
                    for (let i in res.data) {
                        this.farmItems.push({
                            company_cd: res.data[i].company_cd,
                            company_name: res.data[i].company_name,
                            code: res.data[i].farm_cd,
                            name: res.data[i].farm_name,
                        });
                    }
                }
                else {
                    alert(res.message);
                }
            }).catch((err) => {
                console.log("comboFarms API 호출 오류", err);
                alert(err);
            });
        },
        changeFarm() {
            if (this.farm && this.farm.code) {
                this.building = {};
            }
        },
        comboBuildings() {
            Apis.comboBuildings({
                company_cd: "",
                farm_cd: this.farm && this.farm.code || "",
                stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"),
            }, (res) => {
                if (res.result) {
                    this.buildingItems = [];
                    for (let i in res.data) {
                        this.buildingItems.push({
                            company_cd: res.data[i].company_cd,
                            company_name: res.data[i].company_name,
                            farm_cd: res.data[i].farm_cd,
                            farm_name: res.data[i].farm_name,
                            code: res.data[i].building_cd,
                            name: res.data[i].building_name,
                        });
                    }
                }
                else {
                    alert(res.message);
                }
            }).catch((err) => {
                console.log("comboBuildings API 호출 오류", err);
                alert(err);
            });
        },
      changeBuilding() {
        if (this.building) {
          this.farm = {company_cd: this.building.company_cd, company_name: this.building.company_name,
                      code: this.building.farm_cd, name: this.building.farm_name};
        } else  this.building = {};      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      },

    },
}
</script>

<style lang="scss" scoped>
.normal-btn{
  vertical-align: middle !important;
  color:white !important;
  font-size:12pt !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}

.calendar{
    border-color:#3a4f3f;
}

.date{
  font-size: 14px;
  color: rgb(126, 125, 125);
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 2px;
}

</style>